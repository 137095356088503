import React from "react";
import workshops from "../assets/workshops.png";
import nanenane from "../assets/nanenane.png";
import { Outlet, NavLink } from "react-router-dom";

const Projects = () => {
  return (
    <div className="ksm__brand px-[4rem] mt-[-1rem] mb-[10rem]" id="projects">
      <h1 className="text-center xl:mb-[4rem] xs:mb-[2rem] xs:mt-2 uppercase text-2xl">
        Our PROJECTS
      </h1>
      <div className="sabanane flex flex-row xs:flex-col sm:flex-row items-center justify-center gap-8 ">
        <div className="">
          <div className="flex flex-col align-middle justify-center md:w-[350px] xs:w-[270px]">
            <NavLink to="exhibitions">
            <img src={nanenane} alt="Sabasaba Pic" />
            <button class="bg-transparent font-light uppercase hover:bg-[green] text-green cursor mt-2 hover:text-white py-2 px-4 text-[#0F6804] border border-[#0F6804] hover:border-transparent rounded">
              EXHIBITIONS
            </button>
            </NavLink>
          </div>
        </div>
        <div className="">
          <div className="flex flex-col align-middle justify-center md:w-[350px]  xs:w-[270px]">
            
            <NavLink to="workshops">
            <img src={workshops} alt="Nanenane Pic" />
            <button class="bg-transparent font-light uppercase hover:bg-[green] text-green cursor mt-2 hover:text-white py-2 px-4 text-[#0F6804] border border-[#0F6804] hover:border-transparent rounded">
              WORKSHOPS
            </button>
            </NavLink>
          </div>
        </div>
      </div>
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default Projects;
