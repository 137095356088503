import React, { useState, useEffect } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";

import ksm2 from "../assets/ksm2.jpg";
import ksm3 from "../assets/ksm3.jpg";
import ksm4 from "../assets/ksm4.jpg";
import ksm5 from "../assets/ksm5.jpg";
import ksm6 from '../assets/ksm6.jpg'
import ksm7 from '../assets/ksm7.jpg'
import ksm8 from '../assets/ksm8.jpg'

const HeroSlider = () => {
  const ksm_images = [ksm2, ksm3, ksm4, ksm5, ksm6, ksm7, ksm8]
  // const ksm_images = [ksm10, ksm11, ksm12];
  const slideInterval = 5000; // Change image every 5 seconds

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const newIndex = (currentIndex - 1 + ksm_images.length) % ksm_images.length;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const newIndex = (currentIndex + 1) % ksm_images.length;
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, slideInterval);

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="w-full relative group">
      
      <div
        style={{ backgroundImage: `url(${ksm_images[currentIndex]})` }}
        className="w-full bg-no-repeat flex justify-center bg-center bg-cover lg:h-[100vh] xs:h-[70vh] duration-500 relative"
      >
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#013101] opacity-200"></div>

        <div className="absolute top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center  content-center p-0 md:p-8 lg:p-2">
          <h1 className="text-3xl md:text-5xl font-bold mb-2 md:mb-4 lg:mb-6 text-start  sm:text-left">
            Kilimo Rahisi Bila Kemikali
          </h1>
          <div className="flex flex-col justify-center md:flex-row space-y-2 md:space-y-0 md:space-x-4 content-center">
            <button className="bg-[green] hover:bg-black text-white font-bold py-1 md:py-2 lg:py-3 px-2 md:px-4 lg:px-6 rounded"
                    onClick={() => document.getElementById("about").scrollIntoView({ behavior: "smooth" })}>
              About KSM
            </button>
            <button className="bg-transparent hover:bg-[green] text-white font-semibold hover:text-white py-1 md:py-2 lg:py-3 px-2 md:px-4 lg:px-6 border border-[white] hover:border-transparent rounded"
                    onClick={() => document.getElementById("contact").scrollIntoView({ behavior: "smooth" })}>
              Contact Us
            </button>
          </div>
        </div>
      </div>
      <div className="hidden group-hover:block absolute top-[50%] transform -translate-y-1/2 left-3 text-2xl rounded-full p-2 text-white cursor-pointer">
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      <div className="hidden group-hover:block absolute top-[50%] transform -translate-y-1/2 right-3 text-xl rounded-full p-2 text-white cursor-pointer">
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
    </div>
  );
};

export default HeroSlider;
