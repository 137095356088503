import React from 'react'

const Workshops = () => {
  return (
    <div>
      WORKSHOPS
    </div>
  )
}

export default Workshops
