import React, { useState } from "react";

const Contact = () => {
  const [formData, setFormData] = useState({
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://formspree.io/f/mleybnen", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Reset the form after successful submission
        setFormData({
          email: "",
          subject: "",
          message: "",
        });
        alert("Message sent successfully!");
      } else {
        alert("Failed to send message. Please try again later.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div id="contact">
      <div className="font-lato text-base text-gray-900 sm:px-10">
        <div className="text-base text-gray-900">
          <div className="mx-auto w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl">
            <div className="mx-2 pt-12 text-center md:mx-auto md:w-2/3 md:pb-12">
              <h1 className="text-center mb-[2rem] uppercase text-2xl">CONTACT US</h1>
              <div className="text-lg sm:text-xl xl:text-xl">
                <div className="text-gray-900">
                  <p className="mb-4 mt-[-30px] text-[16px]">
                    Please leave us a message; we will be happy to hear from you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto mb-20 flex w-full max-w-screen-lg flex-col overflow-hidden rounded-xl text-gray-900 md:flex-row md:border md:shadow-lg">
          <form
            className="mx-auto w-full max-w-xl border-gray-200 px-10 py-8 md:px-8"
            onSubmit={handleSubmit}
          >
            <div className="mb-4">
              <label className="text mb-2 block font-medium" htmlFor="email">
                Your e-mail:
              </label>
              <input
                className="w-full rounded border border-gray-300 px-3 py-2 outline-none ring-[#0F6804] focus:ring"
                id="email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label className="text mb-2 block font-medium" htmlFor="subject">
                Subject:
              </label>
              <input
                className="w-full rounded border border-gray-300 px-3 py-2 outline-none ring-[#0F6804] focus:ring"
                id="subject"
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-4">
              <label className="text mb-2 block font-medium" htmlFor="message">
                Message:
              </label>
              <textarea
                className="h-52 w-full rounded border border-gray-300 px-3 py-2 outline-none ring-[#0F6804] focus:ring"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <div className="flex items-center">
              <div className="flex-1"></div>
              <button
                className="rounded-xl bg-[#0F6804] px-4 py-3 text-center font-bold text-white hover:bg-black"
                type="submit"
              >
                Send message
              </button>
            </div>
          </form>
          <div className="mt-10 bg-[#0F6804] px-10 py-8 text-gray-100 md:mt-0 md:ml-auto">
            <div className="">
              <p className="mb-4 font-medium border-b pb-2">OFFICE HOURS</p>
              <p className="mb-4">Monday – Friday: 08:00 – 16:00</p>
              <p className="mb-4">Friday: 08:00 - 15:00</p>
              <p className="mb-4">Weekend: Closed</p>
              <p className="mb-4">
                Email: <br/>
                <a href="#" className="font-semibold">
                  info@ksmorganic.co.tz
                </a>
              </p>
              <p className="mb-4">
                Phone: <br/>
                <a href="#" className="font-semibold">
                 +255 763 579 692 <br/>
                </a>
                <a href="#" className="font-semibold">
                +255 652 772 229 <br/>
                </a>
                <a href="#" className="font-semibold">
                +255 742 002 091
                </a>
              </p>
              <hr className="my-2 h-0 border-t border-r-0 border-b-0 border-l-0 border-gray-300" />
           
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
