import { React, useState } from "react";
import logo2 from "../assets/logo2.png";
import { Outlet, NavLink } from "react-router-dom";
const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="absolute w-full  z-10 px-4 py-2  items-center  mx-auto sm:max-w-xl md:max-w-full bg-[#106804]  md:px-24 lg:px-8">
      <div className=" flex items-center mt-1 justify-between">
        <NavLink
          to="/"
          aria-label="KSM"
          title="KSM"
          className="inline-flex items-center no-underline"
        >
          <img src={logo2} className="h-[24px]" alt="KSM Logo" />
        </NavLink>

        <ul className="flex items-center hidden space-x-8 lg:flex">
          <li>
            <NavLink
              to="/"
              className="no-underline font-medium tracking-wide text-[#fff] transition-colors duration-200 hover:text-deep-purple-accent-400"
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="about"
              title="About KSM"
              className="no-underline font-medium tracking-wide text-[#fff] transition-colors duration-200 hover:text-deep-purple-accent-400"
            >
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink
              to="products"
              title="KSM Products"
              className="no-underline font-medium tracking-wide text-[#fff] transition-colors duration-200 hover:text-deep-purple-accent-400"
            >
              Products
            </NavLink>
          </li>
          <li>
            <NavLink
              to="projects"
              title="KSM Projects"
              className="no-underline font-medium tracking-wide text-[#fff] transition-colors duration-200 hover:text-deep-purple-accent-400"
            >
              Projects
            </NavLink>
          </li>
          <li>
            <NavLink
              to="team"
              title="KSM Team"
              className="no-underline font-medium tracking-wide text-[#fff] transition-colors duration-200 hover:text-deep-purple-accent-400"
            >
              Team
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to="blog"
              aria-label="Our Blog"
              title="Blog"
              className="no-underline font-medium tracking-wide text-[#fff] transition-colors duration-200 hover:text-deep-purple-accent-400"
            >
              Blog
            </NavLink>
          </li> */}

          <li>
            <button
              className="bg-black mr-10 hover:bg-black text-white font-bold py-2 px-4 rounded"
              onClick={() =>
                document
                  .getElementById("contact")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              Contact Us
            </button>
          </li>
        </ul>
        <div className="lg:hidden z-50">
          <button
            aria-label="Open Menu"
            title="Open Menu"
            className="no-underline p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
            onClick={() => setIsMenuOpen(true)}
          >
            <svg className="w-5 text-[white]" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
              />
              <path
                fill="currentColor"
                d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
              />
              <path
                fill="currentColor"
                d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
              />
            </svg>
          </button>
          {isMenuOpen && (
            <div className="absolute top-0 left-0 w-full">
              <div className="p-5 bg-[#0F6804] text-[white] z-[100] border rounded shadow-sm">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <NavLink
                      to="/"
                      aria-label="KSM"
                      title="KSM"
                      className="no-underline inline-flex items-center"
                    >
                      <span className="no-underline ml-2 text-xl font-bold tracking-wide text-[#fff] uppercase">
                        KSM
                      </span>
                    </NavLink>
                  </div>
                  <div>
                    <button
                      aria-label="Close Menu"
                      title="Close Menu"
                      className="no-underline p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <svg className="w-5 text-[white]" viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <nav>
                  <ul className="space-y-4">
                    <li>
                      <NavLink
                        to="/"
                       
                        className="no-underline font-medium tracking-wide text-[#fff]transition-colors duration-200 hover:text-deep-purple-accent-400"
                      >
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="about"
                        className="no-underline font-medium tracking-wide text-[#fff] transition-colors duration-200 hover:text-deep-purple-accent-400"
                      >
                        About
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="products"
                        className="no-underline font-medium tracking-wide text-[white] transition-colors duration-200 hover:text-deep-purple-accent-400"
                      >
                        Products
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="projects"
                        className="no-underline font-medium tracking-wide text-[white] transition-colors duration-200 hover:text-deep-purple-accent-400"
                      >
                        Projects
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="team"
                        className="no-underline font-medium tracking-wide text-[white] transition-colors duration-200 hover:text-deep-purple-accent-400"
                      >
                        Team
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="contact"
                        className="no-underline inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide bg-black text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                      >
                        Contact Us
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default Nav;
