import React from 'react'

const Exhibitions = () => {
  return (
    <div>
      EXHIBITIONS
    </div>
  )
}

export default Exhibitions
