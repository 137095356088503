import "./App.css";
import {
  Hero,
  Nav,
  HeroSlider,
  About,
  Patners,
  Awards,
  Projects,
  Team,
  Blog,
  Contact,
  AboutPage,
  Products,
  Exhibitions,
  Workshops,
  ProjectsPage,
} from "./components";

import Layout from "./layouts/Layout";

import {
  BrowserRouter as Router,
  RouterProvider,
  Route,
  Routes,
  BrowserRouter,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import Footer from "./components/Footer";
import TeamPage from "./components/TeamPage";
// import "bootstrap/dist/css/bootstrap.min.css";

// const router = createBrowserRouter(
//   createRoutesFromElements(
    
//     <Route path="/" element = {<Layout/>}>
//       <Route path="about" element={<AboutPage />} />
//         <Route path="products" element={<Products />} />
//         <Route path="projects" element={<Projects />}>
//           <Route path="exhibitions" element={<Exhibitions />} />
//           <Route path="workshops" element={<Workshops />} />
//         </Route>
      
//     </Route>
//   )
// );

const App = () => {
  return (
    // <RouterProvider router={router} />

    <BrowserRouter>
    <Nav/>
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="products" element={<Products />} />
        <Route path="team" element= {<TeamPage/>} />
        <Route path="projects" element={<ProjectsPage />}>
          <Route path="exhibitions" element={<Exhibitions />} />
          <Route path="workshops" element={<Workshops />} />
        </Route>
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
};
{
}
export default App;