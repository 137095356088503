import React from "react";
import ksm_team from "../assets/ksm8.jpg";
import Projects from "./Projects";
import { Outlet, NavLink } from "react-router-dom";
import workshops from "../assets/workshops.png";
import nanenane from "../assets/nanenane.png";
import kisamo from "../assets/kisamo.png";
import ksm_team1 from "../assets/ksm_team1.png";
import ksm_team2 from "../assets/ksm_team2.png";
import ksm_team3 from "../assets/ksm_team3.png";
import ksm_team4 from "../assets/ksm_team4.png";


const TeamPage = () => {
  return (
    <div>
      <div class="bg-white py-6 sm:py-8 lg:py-12">
        <div class="mx-auto max-w-screen-xl px-4 md:px-8">
          <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
            <div class="md:pt-8 xs:mt-[4rem] lg:flex lg:flex-col lg:justify-center">
              <p class="text-center font-bold text-green-500 md:text-left">
                The Dream Team
              </p>

              <h1 class="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6 md:text-left">
                KSM Team
              </h1>

              <p class="mb-6 sm:text-lg md:mb-8">
                Kilimo Sustainable Movement (KSM) LTD as an innovative
                agricultural business that focuses on addressing the challenges
                faced by farmers in Tanzania., has an aggresive team members who
                are passionate on Agriculture.
              </p>
            </div>
            <div>
              <div class="h-64 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-auto mt-8">
                <img
                  src={ksm_team}
                  loading="lazy"
                  alt="KSM TEAM"
                  class="h-full w-full object-cover object-center"
                />
              </div>
            </div>
            <div className="flex items-center content-center md:px-[50%]"></div>
          </div>
        </div>
      </div>
      <div className="px-[4rem] mt-[-4rem] mb-[6rem]" id="team">
        <h1 className="text-center mb-[2rem] uppercase text-2xl">KSM TEAM</h1>
        <section className="flex flex-wrap justify-center gap-8 px-[2rem]">
          <div className="flex items-center">
            <div className="flex flex-col items-center justify-center w-[250px]">
              <img src={kisamo} alt="KSM TEAM Pic" />
              <h2 className="font-bold">Frank Kisamo</h2>
              <p>Founder & C.E.O</p>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex flex-col items-center justify-center w-[250px]">
              <img src={ksm_team1} alt="KSM TEAM Pic" />
              <h2 className="font-bold">Ceylin Malifedha</h2>
              <p>Business Manager </p>
            </div>
          </div>

          <div className="flex items-center">
            <div className="flex flex-col items-center justify-center w-[250px]">
              <img src={ksm_team2} alt="Nanenane Pic" />
              <h2 className="font-bold">Edward Mtimba</h2>
              <p>Product Developer & Agronomist</p>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex flex-col items-center justify-center w-[250px]">
              <img src={ksm_team3} alt="Nanenane Pic" />
              <h2 className="font-bold">Mwanah Adinani</h2>
              <p>Agronomist</p>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex flex-col items-center justify-center w-[250px]">
              <img src={ksm_team4} alt="Nanenane Pic" />
              <h2 className="font-bold">Ikunda Massawe</h2>
              <p className="text-center">
                PHD in Sustainable Agriculture <br />( NM-AIST )
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default TeamPage;
