import React from "react";
import "./Patners.css";
import Slider from "react-slick";
import somo from "../assets/somo.png";
import taha from "../assets/taha.png";
import wsh from "../assets/wsh.png";
import empower from "../assets/empower.png";


import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
const Patners = () => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="ksm__brand px-[4rem] mt-[2rem] mb-[6rem]">
      <h1 className="text-center mb-[2rem] uppercase text-2xl">Our Patners</h1>
      <Slider {...settings}>
        <div>
          <a href="" target="_blank">
          <img src={somo} alt="Somo Africa"  className="mx-auto block w-70 max-w-250 h-auto"/>
          </a>
        </div>
        <div>
          <a href="" target="_blank">
          <img src={empower} alt="Empower" />
          </a>
        </div>
        <div>
          <a href="" target="_blank">
          <img src={taha} alt="TAHA" />
          </a>
        </div>
        <div>
          <a href="#" target="_blank">
          <img src={wsh} alt="Westerwelle Foundation." />
          </a>
        </div>
       
      
        
      </Slider>
    </div>
  );
};

export default Patners;
