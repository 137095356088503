import React from "react";
import {
  Hero,
  Nav,
  HeroSlider,
  About,
  Patners,
  Awards,
  Projects,
  Team,
  Blog,
  Contact,
  AboutPage,
  Footer,
} from "../components";

const Layout = () => {
  return <div>
    <Nav/>
    <HeroSlider/>
    <Patners/>
    <About/>
    <Projects/>
    <Team/>
    <Contact/>
    {/* <Footer/> */}
  </div>;
};

export default Layout;