import React from "react";
import ksm_hero from "../assets/ksm_hero.jpg";

const AboutPage = () => {
  return (
    <div>
      <div class="bg-white py-6 sm:py-8 lg:py-12">
        <div class="mx-auto max-w-screen-xl px-4 md:px-8">
          <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
            
            <div class="md:pt-8 xs:mt-10 lg:flex lg:flex-col lg:justify-center">
              
              <p class="text-center font-bold text-green-500 md:text-left">
                Who we are
              </p>

              <h1 class="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6 md:text-left">
                Operational since 2021
              </h1>

              <p class="mb-6 sm:text-lg md:mb-8">
                Kilimo Sustainable Movement (KSM) LTD is an innovative
                agricultural business that focuses on addressing the challenges
                faced by farmers in Tanzania. "Kilimo" is a Swahili word that
                translates to "agriculture," and our organization aims to
                revolutionize the agriculture sector by introducing sustainable
                and eco-friendly practices.
              </p>
            </div>
            <div>
              <div class="h-64 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-auto mt-8">
                <img
                  src={ksm_hero}
                  loading="lazy"
                  alt="KSM ABOUT US"
                  class="h-full w-full object-cover object-center"
                />
              </div>
            </div>
            <div class="md:col-span-2">
              <h2 class="mb-2 text-center text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4 md:text-left">
                Establishment and Registration
              </h2>

              <p class="mb-6  sm:text-lg md:mb-8">
                Kilimo Sustainable Movement (KSM) LTD was founded in 2021 with a
                vision to make a positive impact on the agricultural landscape
                in Tanzania. In 2023, the company was officially incorporated
                and registered under the number 165853091.
              </p>
            </div>
            <div class="md:col-span-2">
              <h2 class="mb-2 text-center text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4 md:text-left">
                Our Mission
              </h2>

              <p class="mb-6  sm:text-lg md:mb-8">
                Our mission is to empower farmers and promote sustainable
                agriculture practices that enhance productivity, preserve the
                environment, and ensure food security. We strive to be a driving
                force in transforming the agricultural sector towards a more
                sustainable and prosperous future. Addressing Agricultural
                Challenges: KSM was born out of the realization of the numerous
                challenges faced by farmers in Tanzania. Some of the significant
                challenges include:
                <div className="mt-5 ml-8">
                  <span className="font-bold">1. High Production Costs: </span>
                  We understand the burden of high production costs faced by
                  farmers, which can limit their profitability and
                  sustainability. At KSM, we aim to introduce cost-effective
                  solutions that optimize resources and increase yields.
                  <br />
                  <br />{" "}
                  <span className="font-bold">
                    2. Environmental Degradation from Chemical Fertilizers:{" "}
                  </span>
                  The excessive use of chemical fertilizers has resulted in
                  environmental degradation, negatively impacting soil health
                  and water quality. KSM is committed to promoting sustainable
                  farming practices that minimize reliance on chemical
                  fertilizers and protect the environment. <br />
                  <br />{" "}
                  <span className="font-bold">
                    3. Health Issues from Food Safety:
                  </span>{" "}
                  Food safety is a critical concern, and it is directly linked
                  to the use of chemical fertilizers and pesticides. KSM
                  advocates for safe and organic farming methods to ensure
                  healthier produce and improved public health.
                </div>
              </p>
            </div>
            <div class="md:col-span-2">
              <h2 class="mb-2 text-center text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4 md:text-left">
                Our Approach
              </h2>

              <p class="mb-6  sm:text-lg md:mb-8">
                KSM takes a holistic approach to address these challenges by
                offering innovative and sustainable solutions. Our team of
                experts collaborates with farmers to implement modern
                agricultural techniques, such as organic farming, crop rotation,
                and integrated pest management. Through capacity building and
                knowledge sharing, we equip farmers with the necessary skills
                and tools to improve productivity while safeguarding the
                environment and consumer health.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex py-20">
        <div class="border-white/20 relative mx-auto my-5 w-full max-w-4xl rounded-lg border bg-gradient-to-b from-green-900 to-green-600 shadow-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="absolute top-2 right-2 h-6 w-6 cursor-pointer text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
          <div class="p-8 md:p-12 lg:px-16">
            <div class="max-w-lg">
              <h2 class="text-2xl font-bold text-white md:text-3xl">
                Join the Sustainable Movement:
              </h2>

              <p class="hidden text-white sm:mt-4 sm:block">
                We invite farmers, stakeholders, and partners to join our
                sustainable movement towards a thriving agricultural sector in
                Tanzania. Together, we can create a greener and more prosperous
                future for agriculture, benefiting both farmers and the nation.
              </p>
            </div>

            <div class="mt-8 max-w-xl">
              <form action="#" class="sm:flex sm:gap-4">
                <div class="sm:flex-1">
                  <label for="email" class="sr-only">
                    Email
                  </label>

                  <input
                    type="email"
                    placeholder="Email address"
                    class="w-full rounded-md border-indigo-200 bg-white p-3 text-indigo-700 shadow-sm transition focus:border-white focus:outline-none focus:ring focus:ring-indigo-200"
                  />
                </div>

                <button
                  type="submit"
                  class="group mt-4 flex w-full items-center justify-center rounded-md bg-green-400 px-5 py-3 text-white transition sm:mt-0 sm:w-auto focus:outline-none focus:ring focus:ring-indigo-200"
                >
                  <span class="text-sm font-medium"> Join </span>

                  <svg
                    class="group-hover:translate-x-2 ml-3 h-5 w-5 transition-all"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutPage;
