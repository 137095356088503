import React from "react";
import kisamo from "../assets/kisamo.png";
import ksm_team1 from "../assets/ksm_team1.png";
import ksm_team2 from "../assets/ksm_team2.png";
import ksm_team3 from "../assets/ksm_team3.png";
import ksm_team4 from "../assets/ksm_team4.png";

const Team = () => {
  return (
    <div className="px-[4rem] mt-[-4rem] mb-[6rem]" id="team">
      <h1 className="text-center mb-[2rem] uppercase text-2xl">KSM TEAM</h1>
      <section className="flex flex-wrap justify-center gap-8 px-[2rem]">
        <div className="flex items-center">
          <div className="flex flex-col items-center justify-center w-[250px]">
            <img src={kisamo} alt="KSM TEAM Pic" />
            <h2 className="font-bold">Frank Kisamo</h2>
            <p>Founder & C.E.O</p>
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex flex-col items-center justify-center w-[250px]">
            <img src={ksm_team1} alt="KSM TEAM Pic" />
            <h2 className="font-bold">Ceylin Malifedha</h2>
            <p>Business Manager </p>
          </div>
        </div>
        
        
        <div className="flex items-center">
          <div className="flex flex-col items-center justify-center w-[250px]">
            <img src={ksm_team2} alt="Nanenane Pic" />
             <h2 className="font-bold">Edward Mtimba</h2>
            <p>Product Developer & Agronomist</p>
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex flex-col items-center justify-center w-[250px]">
            <img src={ksm_team3} alt="Nanenane Pic" />
            <h2 className="font-bold">Mwanah Adinani</h2>
            <p>Agronomist</p>
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex flex-col items-center justify-center w-[250px]">
            <img src={ksm_team4} alt="Nanenane Pic" />
            <h2 className="font-bold">Ikunda  Massawe</h2>
            <p className="text-center">PHD in Sustainable Agriculture <br/>( NM-AIST )</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Team;
