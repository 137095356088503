import React from "react";
import ksm_prd2 from "../assets/ksm_prd2.jpg";

const Products = () => {
  return (
    // <div className="relative flex flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0">
    //   <div className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0">
    //     <svg
    //       className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
    //       viewBox="0 0 100 100"
    //       fill="currentColor"
    //       preserveAspectRatio="none slice"
    //     >
    //       <path d="M50 0H100L50 100H0L50 0Z" />
    //     </svg>
    //     <img
    //       className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
    //       src={ksm_hero}
    //       alt="KSM"
    //     />
    //   </div>
    //   <div className="relative flex flex-row items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
    //     <div className="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
    //       <h2 className="mb-5 font-sans text-[16px] font-bold tracking-tight text-[#0F6804]  sm:text-6xl sm:leading-tight xs:leading-tight">
    //         KSM Products
    //       </h2>

    //     </div>
    //   </div>
    // </div>
    <div>
      <div class="mx-auto max-w-screen-xl px-4 md:px-8">
        <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
          <div class="md:pt-8 lg:flex lg:flex-col lg:justify-center">
            <h1 class="mb-4 text-center mt-[80px] text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6 md:text-start">
              KSM Products
            </h1>

            <p class="mb-6 sm:text-lg md:mb-8 ">
              Kilimo Sustainable Movement (KSM) LTD as an innovative
              agricultural business that focuses on addressing the challenges
              faced by farmers in Tanzania as succefully come with a variety of
              products that revolutionize the agriculture industry.
            </p>
          </div>
          <div>
            <div class="h-64 overflow-hidden  rounded-lg bg-gray-100 shadow-lg md:h-auto lg:mt-8">
              <img
                src={ksm_prd2}
                loading="lazy"
                alt="KSM ABOUT US"
                class="h-full w-full object-cover object-center"
              />
            </div>
          </div>
          <div class="md:col-span-2">
            <h2 class="mb-2 text-center text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4 md:text-left">
              IMARA Bio-Booster
            </h2>

            <p class="mb-6  sm:text-lg md:mb-8">
              MARA Bio-Booster is a revolutionary agricultural product that
              embodies its Swahili name, "Imara," meaning strong. It is
              developed using organic waste from food and animal and plant
              residues of leguminous plants. This non-technology fertilizer is
              currently undergoing trials and research in horticulture crop
              production.
              <br />
              <br />
              IMARA Bio-Booster stands out as a dual-function product, serving
              as both an insect repellent and a plant growth enhancer, making it
              a versatile "two-in-one" solution. Organic Ingredients for
              Sustainable Farming: IMARA Bio-Booster is formulated with a unique
              blend of organic materials, making it an eco-friendly and
              sustainable option for farmers. <br />
              <br />
              It utilizes food waste and animal and plant residues, particularly
              from leguminous plants. By transforming these natural resources
              into a potent fertilizer, IMARA Bio-Booster contributes to
              reducing waste and supporting environmentally conscious
              agricultural practices.
            </p>
          </div>
          <div class="md:col-span-2">
            <h2 class="mb-2 text-center text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4 md:text-left">
              Features of IMARA Bio-Booster:
            </h2>

            <p class="mb-6  sm:text-lg md:mb-8">
              <div className="mt-5 ml-8">
                <span className="font-bold">1.Natural Insect Repellent: </span>
                The unique composition of IMARA Bio-Booster harnesses its
                natural insect-repellent properties. By utilizing organic
                compounds found in the blend, this fertilizer acts as a
                protective barrier against harmful insects, safeguarding crops
                from potential damage.
                <br />
                <br />{" "}
                <span className="font-bold">2.Plant Growth Enhancer: </span>
                Beyond its insect-repellent capabilities, IMARA Bio-Booster
                doubles as a powerful plant growth enhancer. Its rich nutrients
                nourish the soil and promote healthy root development, leading
                to increased plant vitality, resilience, and higher yields.
                <br />
              </div>
            </p>
          </div>
          <div class="md:col-span-2">
            <h2 class="mb-2 text-center text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4 md:text-left">
              Field Trials and Positive Impact:
            </h2>

            <p class="mb-6  sm:text-lg md:mb-8">
              IMARA Bio-Booster has undergone rigorous testing, with over 1200
              farmers across Tanzania participating in field trials. The results
              have been remarkable, showcasing significant improvements in crop
              health and productivity. Farmers have reported a reduction in pest
              infestations and observed accelerated plant growth, leading to
              enhanced overall crop performance.
            </p>
          </div>
          <div class="md:col-span-2">
            <h2 class="mb-2 text-center text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4 md:text-left">
              A Product of Tanzanian Youth Innovation:
            </h2>

            <p class="mb-6  sm:text-lg md:mb-8">
              The ingenuity behind IMARA Bio-Booster is attributed to the
              creativity and determination of Tanzanian youth. Driven by their
              passion for sustainable agricult ure and environmental
              conservation, these young innovators have developed a product that
              aligns with Tanzania's agricultural needs and supports the
              nation's journey towards more resilient and ecologically
              responsible farming practices.
            </p>
          </div>
          <div class="md:col-span-2">
            <h2 class="mb-2 text-center text-xl font-semibold text-gray-800 sm:text-2xl md:mb-4 md:text-left">
              Join the IMARA Bio-Booster Movement:
            </h2>

            <p class="mb-6  sm:text-lg md:mb-8">
              As we continue to witness the positive impact of IMARA Bio-Booster
              in Tanzanian agriculture, we invite farmers and stakeholders to
              join us in embracing this revolutionary organic fertilizer. By
              adopting IMARA Bio-Booster, farmers can contribute to the
              development of a greener, healthier, and more productive
              agricultural landscape, benefitting both the environment and
              communities. <br /> <br />
              <span className="font-bold">
                Contact us at +255 763 579 692 / +255 652 772 229
              </span>{" "}
              For inquiries about IMARA Bio-Booster or to access the product
              <br /> <br />
              <span className="italic">
                Together, Let's Strengthen Tanzanian Agriculture with IMARA
                Bio-Booster!
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Products;
