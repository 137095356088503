import React from "react";
import ksm_hero from "../assets/ksm_hero.jpg";
import Projects from "./Projects";
import { Outlet, NavLink } from "react-router-dom";
import workshops from "../assets/workshops.png";
import nanenane from "../assets/nanenane.png";

const ProjectsPage = () => {
  return (
    <div>
      <div class="bg-white py-6 sm:py-8 lg:py-12">
        <div class="mx-auto max-w-screen-xl px-4 md:px-8">
          <div class="grid gap-8 md:grid-cols-2 lg:gap-12">
            <div class="md:pt-8 xs:mt-10 lg:flex lg:flex-col lg:justify-center">
              {/* <p class="text-center font-bold text-green-500 md:text-left">
                What we have on hand
              </p> */}

              <h1 class="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6 md:text-left">
                KSM Projects
              </h1>

              <p class="mb-6 sm:text-lg md:mb-8">
                Kilimo Sustainable Movement (KSM) LTD is an innovative
                agricultural business that focuses on addressing the challenges
                faced by farmers in Tanzania. "Kilimo" is a Swahili word that
                translates to "agriculture," and our organization aims to
                revolutionize the agriculture sector by introducing sustainable
                and eco-friendly practices.
              </p>
            </div>
            <div>
              <div class="h-64 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:h-auto mt-8">
                <img
                  src={ksm_hero}
                  loading="lazy"
                  alt="KSM ABOUT US"
                  class="h-full w-full object-cover object-center"
                />
              </div>
            </div>
            <div className="flex items-center content-center md:px-[50%]">

            
            <div className="sabanane flex flex-row xs:flex-col sm:flex-row items-center justify-center gap-8 ">
              <div className="">
                <div className="flex flex-col align-middle justify-center md:w-[350px] xs:w-[270px]">
                  <NavLink to="exhibitions">
                    <img src={nanenane} alt="Sabasaba Pic" />
                    <button class="bg-transparent font-light uppercase hover:bg-[green] text-green cursor mt-2 hover:text-white py-2 px-4 text-[#0F6804] border border-[#0F6804] hover:border-transparent rounded">
                      EXHIBITIONS
                    </button>
                  </NavLink>
                </div>
              </div>
              <div className="">
                <div className="flex flex-col align-middle justify-center md:w-[350px]  xs:w-[270px]">
                  <NavLink to="workshops">
                    <img src={workshops} alt="Nanenane Pic" />
                    <button class="bg-transparent font-light uppercase hover:bg-[green] text-green cursor mt-2 hover:text-white py-2 px-4 text-[#0F6804] border border-[#0F6804] hover:border-transparent rounded">
                      WORKSHOPS
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
            </div>
            {/* <div className="flex justify-center content-center">
              <Projects />
            </div> */}
          </div>
        </div>
      </div>
      <div class="flex py-20">
        <div class="border-white/20 relative mx-auto my-5 w-full max-w-4xl rounded-lg border bg-gradient-to-b from-green-900 to-green-600 shadow-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="absolute top-2 right-2 h-6 w-6 cursor-pointer text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
          <div class="p-8 md:p-12 lg:px-16">
            <div class="max-w-lg">
              <h2 class="text-2xl font-bold text-white md:text-3xl">
                Join the Sustainable Movement:
              </h2>

              <p class="hidden text-white sm:mt-4 sm:block">
                We invite farmers, stakeholders, and partners to join our
                sustainable movement towards a thriving agricultural sector in
                Tanzania. Together, we can create a greener and more prosperous
                future for agriculture, benefiting both farmers and the nation.
              </p>
            </div>

            <div class="mt-8 max-w-xl">
              <form action="#" class="sm:flex sm:gap-4">
                <div class="sm:flex-1">
                  <label for="email" class="sr-only">
                    Email
                  </label>

                  <input
                    type="email"
                    placeholder="Email address"
                    class="w-full rounded-md border-indigo-200 bg-white p-3 text-indigo-700 shadow-sm transition focus:border-white focus:outline-none focus:ring focus:ring-indigo-200"
                  />
                </div>

                <button
                  type="submit"
                  class="group mt-4 flex w-full items-center justify-center rounded-md bg-green-400 px-5 py-3 text-white transition sm:mt-0 sm:w-auto focus:outline-none focus:ring focus:ring-indigo-200"
                >
                  <span class="text-sm font-medium"> Join </span>

                  <svg
                    class="group-hover:translate-x-2 ml-3 h-5 w-5 transition-all"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProjectsPage;
