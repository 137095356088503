import React from "react";
import ksm_about from "../assets/ksm_about.png";
import { Outlet, NavLink } from "react-router-dom";

const About = () => {
  return (
    <div className="mb-[6rem]" id="about">
      <h1 className="text-center mb-[1rem] uppercase text-2xl">ABOUT US</h1>

      <section className="flex  lg:flex-row sm:flex-col xs:flex-col items-center justify-center lg:px-[4rem]  xs:px-[2rem] gap-2">
        <div className="">
          <img
            alt="ABOUT KSM"
            src={ksm_about}
            className="lg:max-w-xl xs:max-w-xs h-auto"
          />
        </div>
        <div className="xs:text-start">
          <p>
            KSM LTD is an agricultural business that was founded in 2021 with
            the mission to empower farmers and promote sustainable agriculture
            practices. KSM aims to address the challenges faced by farmers in
            Tanzania, such as high production costs, environmental degradation,
            and food safety issues.
          </p>
          <br />
          <p>
            KSM takes a holistic approach to address these challenges by
            offering innovative and sustainable solutions, such as organic
            farming, crop rotation, and integrated pest management. KSM invites
            farmers, stakeholders, and partners to join their sustainable
            movement towards a thriving agricultural sector in Tanzania.
          </p>

          {/* <button class="bg-[green] mr-10 hover:bg-black text-white font-bold py-2 px-4 rounded mt-4">
              Read More
            </button> */}

          <NavLink to="about">
            <button class="bg-[green] mt-3 text-white font-bold py-2 px-4 rounded">
              Read More
            </button>
          </NavLink>
          
        </div>
      </section>
      <main>
        <Outlet />
      </main>
    </div>
  );
};

export default About;
